import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleAPIKeyClient, GoogleAPIKeyDCD } from '../../environment';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import search from "../../assets/images/RxLink assets/new assets/Search_light.svg";
import { useNavigate, useLocation } from "react-router-dom";
import smallImage from "../../assets/images/smallimg.png";
import PageCounter from './PageCounter';
import { setShowPahrmacyMap, removePharmacyFromList, setFromCoupon, setFromSearchMap, setFromDrawer } from '../../reducers/medication';
import { getUserPharmacyList, addOrUpdatePharmacy, getZipCodeFromLatLng, getNPIRegistry, getLatLngFromAddress } from "./api";
import pharmacyMarker from "../../assets/images/RxLink assets/new assets/map icon collapsed.svg"
import { store } from "../../store";
import blueCheck from "./../../assets/images/RxLink assets/new assets/blue check.svg"
import { MotionIcon } from '../motion/MotionButton';
import { useWindowWidth } from './useWindowWidth';
import trash from "../../assets/images/RxLink assets/new assets/Trash_light.svg";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { errorToast } from '../toast/toast';
import { searchPharmacyLogo } from '../PharmacyLogo';
import { setLoadingPharmacy } from '../../reducers/global';



const SearchPharmacy = (props) => {

    const { ownPosition, setOwnPosition, pharmacyList, setPharmacyList, AddOrUpdatePharmacy, RemovePharmacy, setPharmacyFlag, drugPrices, resetInsurance } = props;
    const dispatch = useDispatch();
    const [markers, setMarkers] = useState([])

    const [mapCenter, setMapCenter] = useState(null);

    const [activeMarker, setActiveMarker] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [infoWindowIsVisible, setInfoWindowIsVisible] = useState(false);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const { windowWidth } = useWindowWidth();

    const [showMap, setShowMap] = useState(false);


    const mapRef = useRef(null);
    const markerRefs = useRef({});

    const sessionLatitude = window.sessionStorage.getItem("latitude");
    const sessionLongitude = window.sessionStorage.getItem("longitude");
    const sessionPostalCode = window.sessionStorage.getItem("postalCode");


    const initialPosition = { lat: sessionLatitude != null ? sessionLatitude : ownPosition?.lat, lng: sessionLongitude != null ? sessionLongitude : ownPosition?.lng };

    useEffect(() => {
        console.log({ pharmacyList });
        if (pharmacyList.length > 0) {
            let defectedPharmacies = pharmacyList.filter(item => {
                // (item.longitude == null && item.lng == null) || (item.latitude == null && item.lat)
                if ((item.longitude == null && item.lng == null) || (item.latitude == null && item.lat == null)) {
                    return true
                }
                else {
                    return false
                }
            });
            if (defectedPharmacies?.length > 0) {
                defectedPharmacies.map((el, i) => {
                    getLatLngFromAddress(el.address,
                        (data) => {
                            console.log({ latLng: data });
                            let pharmaList = pharmacyList.map((e, index) => {
                                if (el.userPharmacySeqNo == e.userPharmacySeqNo) {
                                    let model = {
                                        ...e,
                                        latitude: data.results[0].geometry.location.lat,
                                        longitude: data.results[0].geometry.location.lng,
                                        chain: el.pharmacyName.split(" ")[0].toLowerCase()
                                    }
                                    addOrUpdatePharmacy(model, (data) => {
                                        console.log("*******", data)
                                    })
                                    return model
                                }
                                else {
                                    return e
                                }
                            });
                            setPharmacyList(pharmaList)
                        },
                        (error) => {

                        });
                })
            }
        }
    }, [pharmacyList])


    async function success(position) {
        const crd = position.coords;
        if (parseInt(crd.accuracy) < 5000) {
            const { latitude, longitude } = position.coords;

            await getZipCodeFromLatLng(
                latitude,
                longitude,
                (zipcode) => {
                    if (zipcode != "ZIP code not found") {
                        setOwnPosition(prev => ({ ...prev, lat: latitude, lng: longitude, postalCode: zipcode }));
                    }

                    // else toast.error("ZIP code not found")
                },
                (zipcode) => {
                    setOwnPosition(prev => ({ ...prev, lat: latitude, lng: longitude }));
                })
        }
        setShowMap(true);
    }

    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        setShowMap(true);
    }

    useEffect(() => {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };
        if (navigator.geolocation) {
            if (sessionLatitude == null || sessionLongitude == null || sessionPostalCode == null)
                navigator.geolocation.getCurrentPosition(success, error, options);
            else {
                if (sessionPostalCode == null)
                    getZip(sessionLatitude, sessionLongitude);
                setShowMap(true);
            }
        } else {
            console.error("Geolocation is not supported by this browser.");
            setShowMap(true);
        }

        dispatch(setFromCoupon(false));
    }, []);

    useEffect(() => {
        resetInsurance();
    }, [])

    useEffect(() => {
        if (mapRef.current) {
            showAllInfoWindows();
            setInfoWindowIsVisible(true);
        }
    }, [mapRef.current, markers]);

    const getZip = async (lat, lng) => {
        await getZipCodeFromLatLng(
            lat,
            lng,
            (zipcode) => {
                if (zipcode.zipcode != "ZIP code not found") {
                    setOwnPosition(prev => ({ ...prev, lat: lat, lng: lng, postalCode: zipcode }));
                    localStorage.setItem("lcn", { lat: lat, lng: lng, postalCode: zipcode })
                }
                // else toast.error("ZIP code not found")
            },
            (zipcode) => {
                setOwnPosition(prev => ({ ...prev, lat: lat, lng: lng }));
                localStorage.setItem("lcn", { lat: lat, lng: lng })                // toast.error("ZIP code not found");
            })
    }

    const showAllInfoWindows = () => {
        markers.forEach((marker) => {
            const markerRef = markerRefs.current[marker.id];
            if (markerRef) {
                const infoWindow = markerRef.infoWindow;
                if (infoWindow) {
                    infoWindow.open(mapRef.current, markerRef);
                }
            }
        });
    };

    useEffect(() => {
        setMarkers([])
        pharmacyList.map((pharmacy, index) => {
            if (pharmacy.userPharmacySeqNo) {
                setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.latitude, lng: pharmacy.longitude }, ...prev])
            }
            else
                setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.lat, lng: pharmacy.lng }, ...prev])
        })
        if (pharmacyList.length > 0) {
            setMapCenter({ lat: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].latitude : pharmacyList[0].lat, lng: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].longitude : pharmacyList[0].lng })
        }
        if (pharmacyList.length == 0 && !!ownPosition) {
            setMapCenter({ lat: ownPosition.lat, lng: ownPosition.lng })
        }
    }, [pharmacyList])


    const onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    }

    const getAddress = ({ lat, lng }) => {
        let filteredPharmacy = pharmacyList.filter((i, index) => {
            if (i.latitude == lat && i.longitude == lng) return true;
            else return false
        })
        return filteredPharmacy;
    }

    // console.log({ ownPosition })

    const handleMarkerDragend = async (mapProps, map, clickEvent) => {
        // console.log('New marker position:', clickEvent.latLng.lat(), clickEvent.latLng.lng());
        // console.log({ clickEvent })
        setOwnPosition(prev => ({ ...prev, lat: clickEvent.latLng.lat(), lng: clickEvent.latLng.lng() }));
        window.sessionStorage.setItem("latitude", clickEvent.latLng.lat())
        window.sessionStorage.setItem("longitude", clickEvent.latLng.lng())


        await getZipCodeFromLatLng(
            clickEvent.latLng.lat(),
            clickEvent.latLng.lng(),
            (zipcode) => {
                if (zipcode != "ZIP code not found") {
                    setOwnPosition(prev => ({ ...prev, lat: clickEvent.latLng.lat(), lng: clickEvent.latLng.lng(), postalCode: zipcode }));
                    // localStorage.setItem("lcn", {lat: clickEvent.latLng.lat(), lng: clickEvent.latLng.lng(), postalCode: zipcode})
                    window.sessionStorage.setItem("postalCode", zipcode);
                }
                else errorToast("ZIP code not found")
            },
            (zipcode) => {
                setOwnPosition(prev => ({ ...prev, lat: clickEvent.latLng.lat(), lng: clickEvent.latLng.lng() }));
                // localStorage.setItem("lcn", {lat: clickEvent.latLng.lat(), lng: clickEvent.latLng.lng()})

                errorToast("ZIP code not found");
            })

    };

    const getInitialCenter = () => {
        if (pharmacyList && pharmacyList.length > 0)
            return {
                lat: pharmacyList[0].latitude,
                lng: pharmacyList[0].longitude,
            }
        else {
            return {
                lat: ownPosition?.lat,
                lng: ownPosition?.lng
            }
        }
    }

    // useEffect(() => {
    //     console.log({ markers })
    // }, [markers])

    return (
        <div>
            <div
                className="medicine_icon pharma_icon  map-back-icon"
                style={{ position: windowWidth <= 766 ? "fixed" : "relative", zIndex: 100, left: windowWidth <= 766 ? 0 : 55, top: windowWidth <= 766 ? -13 : 20 }}
                onClick={() => {
                    dispatch(setFromSearchMap(true))
                    dispatch(setFromDrawer(false))
                    dispatch(setShowPahrmacyMap(false));
                    const state = store.getState()

                    let list = state.medication.pharmacyList.filter((item) => item.userPharmacySeqNo);
                    setPharmacyList(list);
                }}
            >
                <MotionIcon><i className="fas fa-angle-left"></i></MotionIcon>
            </div>
            {(ownPosition != null && ownPosition.lat != null && ownPosition.lng != null) && showMap && <Map
                google={props.google}
                style={{ width: '100%', height: '100%', position: 'relative' }}
                initialCenter={getInitialCenter()}
                center={mapCenter}
                zoom={14}
                fullscreenControl={false}
                mapTypeControl={false}
                zoomControl={false}
                disableStreetViewControl={true}
            >
                <Marker
                    position={initialPosition}
                    draggable={true}
                    onDragend={handleMarkerDragend}
                />
                {
                    markers.length > 0 && markers.map((marker, index) => {

                        return (
                            <Marker
                                title={marker.name}
                                name={marker.name}
                                key={index}
                                position={{ lat: marker.lat, lng: marker?.lng }}
                                icon={{
                                    url: pharmacyMarker,
                                    anchor: new props.google.maps.Point(32, 32),
                                    scaledSize: new props.google.maps.Size(38, 38)
                                }}
                                onClick={(props, marker, e) => onMarkerClick(props, marker)}
                                ref={(ref) => (markerRefs.current[marker.id] = ref)}
                            />
                        )
                    })
                }
                <InfoWindow
                    marker={activeMarker}
                    visible={showingInfoWindow || infoWindowIsVisible}>
                    <div>
                        <span>{selectedPlace?.name}</span><br />
                        {
                            getAddress({ lat: selectedPlace?.position?.lat || '', lng: selectedPlace?.position?.lng || '' })[0]?.address
                        }
                    </div>
                </InfoWindow>
            </Map>}
            <SearchPharmacyBox
                windowWidth={windowWidth}
                setPharmacyList={setPharmacyList}
                pharmacyList={pharmacyList}
                isLoaded={true}
                google={props.google}
                AddOrUpdatePharmacy={AddOrUpdatePharmacy}
                setPharmacyFlag={setPharmacyFlag}
                ownPosition={ownPosition}
                drugPrices={drugPrices}
                RemovePharmacy={RemovePharmacy}
                setMarkers={setMarkers}
            />
        </div>
    );

}
export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(SearchPharmacy);



const SearchPharmacyBox = ({ windowWidth, pharmacyList, setPharmacyList, google, AddOrUpdatePharmacy, RemovePharmacy, ownPosition, drugPrices, setMarkers }) => {


    const [slider, showSlider] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const navigate = useNavigate();

    const [showCross, setShowCross] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const [searchValue, setSearchValue] = useState('');
    const inputRef = useRef();
    const user = JSON.parse(localStorage.getItem("user"));
    const hasPharmacy = user?.hasPharmacy;

    const pharmacyListRedux = useSelector((state) => state.medication.pharmacyList);

    // console.log({ pharmacyListRedux })


    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
        setNoResults(false);
    };

    const handleClearClick = () => {
        setSearchValue('');
        setNoResults(false);
    };

    useEffect(() => {
        if (searchValue.length > 0) setShowCross(true)
        else setShowCross(false)
    }, [searchValue])

    const medication = () => {
        navigate("/medications");
    };


    const arr = pharmacyList;
    const isTab = windowWidth > 766 && windowWidth <= 992;
    const isDesktop = windowWidth > 992;
    const isMobile = (isTab == false && isDesktop == false);


    const onPlaceSelected = async (place, callback) => {

        const state = store.getState()
        const list = state.medication.pharmacyList;
        // console.log({ list })
        if (place && !!place.address_components) {
            const postalCode = place.address_components.find((component) =>
                component.types.includes('postal_code')
            );
            const street = place.address_components.find((component) =>
                component.types.includes('street_number')
            );
            const route = place.address_components.find((component) =>
                component.types.includes('route')
            );
            const locality = place.address_components.find((component) =>
                component.types.includes('locality')
            );
            const administrative_area_level_1 = place.address_components.find((component) =>
                component.types.includes('administrative_area_level_1')
            );
            const country = place.address_components.find((component) =>
                component.types.includes('country')
            );

            if (callback) {
                // callback(result.formatted_address)
                callback('')
            }
            if (!callback) {
                setSearchValue('')
            }
            // console.log({ pharmacyListRedux })
            if (!!setPharmacyList) {
                setPharmacyList([{
                    pharmacyName: place.name || '',
                    logo: { small: place.icon || '' },
                    route: route?.long_name || '',
                    locality: locality?.long_name || "",
                    political: administrative_area_level_1?.short_name || '',
                    country: country?.short_name || '',
                    lat: place.geometry.location.lat() || 0,
                    lng: place.geometry.location.lng() || 0,
                    postalCode: !!postalCode ? postalCode.long_name : "",
                    street: street?.long_name || "",
                    formattedAddress: place.formatted_address,
                    vicinity: place.vicinity
                }, ...list]);
            }
        }
    }

    useEffect(() => {

        if (ownPosition) {
            const latitude = ownPosition?.lat || 33.9487112;
            const longitude = ownPosition?.lng || -84.33489209999999;
            const bounds = {
                north: parseFloat(latitude) + 0.02,
                south: parseFloat(latitude) - 0.02,
                east: parseFloat(longitude) + 0.02,
                west: parseFloat(longitude) - 0.02,
            };

            const autocomplete = new google.maps.places.Autocomplete(
                inputRef.current,
                {
                    types: ['pharmacy', 'drugstore'],
                    componentRestrictions: { country: 'us' },
                    bounds: bounds,
                    strictBounds: false,
                    rankBy: 'distance'
                }
            );
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    setNoResults(true);
                } else {
                    setNoResults(false);
                    onPlaceSelected(place, setSearchValue);
                }
            });
        }

    }, [google.maps.places, ownPosition]);
    return (
        <>
            {isDesktop && <div className='webView-search-container'>
                {!hasPharmacy && <PageCounter />}
                <MedicationText hasPharmacy={hasPharmacy} />
                <PharmacySearchInput setPharmacyList={setPharmacyList} onPlaceSelected={onPlaceSelected} google={google} ownPosition={ownPosition} />
                {arr?.length > 0 && <Divider />}
                {arr?.length > 0 && <ListingComponent arr={arr} onClick={AddOrUpdatePharmacy} onDelete={RemovePharmacy} class1="pharma-listing" class2="pharma_inner-extended" isDesktop={isDesktop} drugPrices={drugPrices} setPharmacyList={setPharmacyList} setMarkers={setMarkers}/>}
            </div>}
            {(isTab || isMobile) &&
                <>
                    {isTab &&
                        <div className='tabView-search-container'>
                            <MedicationText />
                            <PharmacySearchInput setPharmacyList={setPharmacyList} onPlaceSelected={onPlaceSelected} google={google} ownPosition={ownPosition} />
                        </div>
                    }
                    {
                        isMobile && <div className='mobile-input' style={{ height: 60, position: "fixed" }}>
                            <div style={{ display: "flex" }}>
                                <i style={{ position: "absolute", left: 75, top: 16 }}>
                                    <img src={search} />
                                </i>
                                {showCross &&
                                    <i className='xMarkIconMobile'>
                                        <FontAwesomeIcon icon={faXmark} onClick={handleClearClick} />
                                    </i>
                                }
                                <input
                                    type="text"
                                    ref={inputRef}
                                    placeholder="Search for Pharmacy"
                                    className="search-bar pharma-search-bar input_pharma "
                                    value={searchValue}
                                    onChange={handleInputChange}
                                    style={{ flex: 0.95, marginLeft: 65 }}
                                />
                                {noResults && <div style={{ position: "absolute", width: "89.5%", top: 56, left: "10.5%", background: "rgba(250, 253, 255, 1)", borderRadius: 8, padding: 8, boxShadow: "0px 6px 20px rgba(20, 48, 82, 0.12)" }}>No result found</div>} {/* Render "No pharmacy found" message */}
                            </div>
                        </div>
                    }
                    <ListingComponent arr={slider ? arr : arr} onClick={AddOrUpdatePharmacy} onDelete={RemovePharmacy} class1="tab-pharma-listing" class2="pharma_inner-extended" isMobile={isMobile} isTab={isTab} drugPrices={drugPrices} setPharmacyList={setPharmacyList} setMarkers={setMarkers} />

                </>
            }
        </>
    )
}


export const Divider = () => {
    return (
        <div className='section-divider'></div>
    )
}

const SliderButton = ({ setShowMax }) => {

    return (
        <div onClick={() => setShowMax(prev => !prev)} className="slider-contianer">
            <div className='slider-button' ></div>
        </div>
    )
}

const MedicationText = ({ hasPharmacy }) => {
    return (
        <div className="row" style={{ marginBottom: -30 }}>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <h1>
                    <b>{hasPharmacy ? "My" : "Find your"} pharmacy</b>
                </h1>
                <h6>
                    <b>
                        Please select the pharmacy where your
                        medications have been prescribed to
                    </b>
                </h6>
            </div>
        </div>
    )
}
const PharmacySearchInput = ({ setPharmacyList, onPlaceSelected, google, ownPosition }) => {
    const [searchValue, setSearchValue] = useState('');
    const [noResults, setNoResults] = useState(false);

    const inputRef = useRef();

    useEffect(() => {

        if (ownPosition) {
            const latitude = ownPosition?.lat || 33.9487112;
            const longitude = ownPosition?.lng || -84.33489209999999;
            const bounds = {
                north: parseFloat(latitude) + 0.02,
                south: parseFloat(latitude) - 0.02,
                east: parseFloat(longitude) + 0.02,
                west: parseFloat(longitude) - 0.02,
            };

            const autocomplete = new google.maps.places.Autocomplete(
                inputRef.current,
                {
                    types: ['pharmacy', 'drugstore'],
                    componentRestrictions: { country: 'us' },
                    bounds: bounds,
                    strictBounds: false,
                    rankBy: 'distance'
                }
            );
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    setNoResults(true);
                } else {
                    setNoResults(false);
                    onPlaceSelected(place, setSearchValue);
                }
            });
        }

    }, [google.maps.places, ownPosition]);

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
        setNoResults(false);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
          const pacContainer = document.querySelector('.pac-container'); // Get suggestion container
    
          if (pacContainer) {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
              setTimeout(() => {
                const selectedItem = document.querySelector('.pac-item-selected'); // Get selected suggestion item
                if (selectedItem) {
                  // Scroll the selected item into view
                  selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
              }, 100); // Delay ensures that Google Maps has applied the selected class
            }
          }
        };
    
        // Attach event listener to input
        const inputElement = inputRef.current;
        inputElement && inputElement.addEventListener('keydown', handleKeyDown);
    
        return () => {
          inputElement && inputElement.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
    return (
        <><span style={{ position: "relative", top: 41, left: 12 }}>
            <i className='search_icon_pharmacy'>
                <img src={search} />
            </i>
        </span>
            <span style={{ position: "relative", float: "right", top: 41, right: 6, zIndex: 5 }}>
                {searchValue?.length > 0 && <i style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faXmark}
                        color='gray'
                        onClick={() => {
                            setSearchValue('');
                            setNoResults(false);
                        }}
                    />
                </i>}
            </span>

            <input
                type="text"
                ref={inputRef}
                placeholder="Search for Pharmacy"
                className="search-bar pharma-search-bar input_pharma pe-4"
                value={searchValue}
                onChange={handleInputChange}
            />
            {noResults && <div className="no-record">No result found</div>} {/* Render "No pharmacy found" message */}

        </>
    );
}

const ListingComponent = ({ arr, onClick, onDelete, class1, class2, isDesktop, isMobile, isTab, drugPrices, setPharmacyList, setMarkers }) => {
    var userDetail = JSON.parse(localStorage.getItem("user"));

    const [showMax, setShowMax] = useState(false);
    const [logos, setLogos] = useState([]);



    const dispatch = useDispatch();

    const getContainerMaxHeight = () => {
        if (!!isDesktop) return "36vh";
        else if (!!isMobile) {
            return showMax ? "85%" : "43%"
        }
        else if (!!isTab) {
            return showMax ? "60%" : "38%"
        }
    }

    useEffect(() => {
        if (arr.length > 0) {
            arr.map((item, index) => {
                let logo = '';
                let isMatching = false;
                drugPrices.some((type, i) => {
                    if (type.pharmacyName.toLowerCase() == item.pharmacyName.split(" ")[0].toLowerCase()) {
                        isMatching = true;
                        logo = type.logo.small;
                    }
                });
                if (isMatching) {
                    setLogos(prev => [logo, ...prev])
                }
                else {
                    setLogos(prev => ['https://cdn.hellohippo.com/assets/pharmacies/logos/default.png', ...prev])
                }
            })
        }
    }, [arr])

    // useEffect(()=>{
    //     console.log({logos})
    // },[logos])

    // console.log({arr})

    return (
        <div>
            <div className={class1} style={{ maxHeight: getContainerMaxHeight() }}>
                {/* {!isDesktop && <SliderButton setShowMax={setShowMax} />} */}
                {!!arr && arr?.map((item, index) => {
                    return (
                        <div className={`${class2}`} key={index} style={{margin : isMobile ?"8px 26px 0px 26px" : "8px 0px 0px 0px" }}>
                            <div className="row">
                                <div className="col-2 col-sm-2 col-md-2 col-lg-2" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={searchPharmacyLogo(item?.pharmacyName.replace(/(?:\s+\w+)?[.!?]?$/, '').trim())} style={{ width: 50 }} />
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center">
                                    <div>
                                        <p style={{ marginBottom: 0, fontSize: 13, fontWeight: "bold" }}>{item?.pharmacyName}</p>
                                        {!item?.address ?
                                            <span style={{ fontSize: 11 }}>{item.street} {item.route}
                                                {/* {item.locality}, {item.political}, {item.country} */}
                                            </span>
                                            : <span style={{ fontSize: 11 }}>{item.address.split(",")[0]}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-3 col-sm-3 col-md-3 col-lg-3 pl-0 pr-0 autoMargin">
                                    <button
                                        className={`btn ${item.isDefault ? 'btn_light' : 'btn_success'}`}
                                        disabled={!!item.isDefault}
                                        style={{ width: "100%" }}
                                        type="submit" onClick={() => {
                                            dispatch(setLoadingPharmacy(true));
                                            if (item.userPharmacySeqNo) {
                                                // debugger;
                                                onClick({
                                                    ...item, isDefault: 1, hasPharmacy: 1,
                                                    // chain: item.pharmacyName.split(" ")[0].toLowerCase()
                                                })
                                            }
                                            else {
                                                let skip = 0;
                                                getNPIRegistry(item.postalCode, skip, 
                                                    (npi) => {

                                                    let tempNpi = npi.results[0].number;
                                                    // console.log({ npi });
                                                    // console.log({ single: npi.results[0] })
                                                    // filter on basis of name
                                                    const filteredNameItem = npi?.results.filter(i => {
                                                        if ((i?.basic?.organization_name?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]/g, ''))?.includes(item?.pharmacyName?.split(" ")[0]?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]/g, ''))) return true;
                                                        else if (i?.basic?.organizational_subpart == "YES") {
                                                            if ((i?.basic?.parent_organization_legal_business_name?.toLowerCase())?.includes(item?.pharmacyName.split(" ")[0].toLowerCase())) return true
                                                        }
                                                        else return false;
                                                    });
                                                    //filter on bases of address
                                                    const filteredAddressItem = filteredNameItem.length > 0 ? filteredNameItem.filter(it => {
                                                        let locAddress = it?.addresses?.filter(ii => ii.address_purpose == "LOCATION");
                                                        var resultant = [];
                                                        if (locAddress.length > 0) {
                                                            resultant = (locAddress[0].address_1).toLowerCase().includes(item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''))
                                                                ? locAddress : [];
                                                            if (resultant.length < 1) {
                                                                let npiAdress = (locAddress[0].address_1).toLowerCase();
                                                                let googleAddress = item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);

                                                                let vicinity = item.vicinity.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);

                                                                // check if npi address include formatted address or vicinity from google address
                                                                resultant = (npiAdress.includes(googleAddress) || npiAdress.includes(vicinity)) ? locAddress : [];
                                                            }
                                                        }
                                                        if (resultant.length > 0) return true; else return false
                                                    }) : [];
                                                    // if we find the npi then post the 
                                                    if (filteredAddressItem.length > 0 || skip == 600)
                                                        onClick({
                                                            chain: item.pharmacyName.toLowerCase(),
                                                            pharmacyName: item?.pharmacyName,
                                                            latitude: item.lat,
                                                            longitude: item.lng,
                                                            zipcode: item.postalCode,
                                                            address: item.formattedAddress,
                                                            description: "",
                                                            isDefault: userDetail.hasPharmacy ? 1 : 0,
                                                            hasPharmacy: userDetail.hasPharmacy ? 1 : 0,
                                                            state: item.political,
                                                            npi: filteredAddressItem.length > 0 ? filteredAddressItem[0].number : null
                                                        })
                                                    //if we don't find npi then we make another API request to get next 200 records
                                                    else {
                                                        skip += 200;
                                                        getNPIRegistry(item.postalCode, skip, 
                                                            (npi) => {
                                                            //find on basis of name
                                                            const filteredNameItem = npi?.results.filter(i => {
                                                                if ((i?.basic?.organization_name?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]/g, ''))?.includes(item?.pharmacyName.split(" ")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''))) return true;
                                                                else if (i?.basic?.organizational_subpart == "YES") {
                                                                    if ((i?.basic?.parent_organization_legal_business_name?.toLowerCase())?.includes(item?.pharmacyName.split(" ")[0].toLowerCase())) return true
                                                                }
                                                                else return false;
                                                            });
                                                            //find on basis of address
                                                            const filteredAddressItem = filteredNameItem.length > 0 ? filteredNameItem.filter(it => {
                                                                let locAddress = it.addresses.filter(ii => ii.address_purpose == "LOCATION");
                                                                var resultant = [];
                                                                if (locAddress.length > 0) {
                                                                    resultant = (locAddress[0].address_1).toLowerCase().includes(item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''))
                                                                        ? locAddress : [];
                                                                    if (resultant.length < 1) {
                                                                        let npiAdress = (locAddress[0].address_1).toLowerCase();
                                                                        let googleAddress = item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);

                                                                        let vicinity = item.vicinity.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);
                                                                        resultant = (npiAdress.includes(googleAddress) || npiAdress.includes(vicinity)) ? locAddress : [];
                                                                    }
                                                                }
                                                                if (resultant.length > 0) return true; else return false
                                                            }) : [];

                                                            if (filteredAddressItem.length > 0 || skip > 600)
                                                                onClick({
                                                                    chain: item.pharmacyName.toLowerCase(),
                                                                    pharmacyName: item?.pharmacyName,
                                                                    latitude: item.lat,
                                                                    longitude: item.lng,
                                                                    zipcode: item.postalCode,
                                                                    address: item.formattedAddress,
                                                                    description: "",
                                                                    isDefault: userDetail.hasPharmacy ? 1 : 0,
                                                                    hasPharmacy: userDetail.hasPharmacy ? 1 : 0,
                                                                    state: item.political,
                                                                    npi: filteredAddressItem.length > 0 ? filteredAddressItem[0].number : null
                                                                })
                                                            else {
                                                                skip += 200;
                                                                getNPIRegistry(item.postalCode, skip, 
                                                                    (npi) => {
                                                                    //find on basis of name
                                                                    const filteredNameItem = npi?.results.filter(i => {
                                                                        if ((i?.basic.organization_name?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]/g, ''))?.includes(item?.pharmacyName.split(" ")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''))) return true;
                                                                        else if (i?.basic?.organizational_subpart == "YES") {
                                                                            if ((i?.basic?.parent_organization_legal_business_name?.toLowerCase())?.includes(item?.pharmacyName.split(" ")[0].toLowerCase())) return true
                                                                        }
                                                                        else return false;
                                                                    });
                                                                    //find on basis of address
                                                                    const filteredAddressItem = filteredNameItem.length > 0 ? filteredNameItem.filter(it => {
                                                                        let locAddress = it.addresses.filter(ii => ii.address_purpose == "LOCATION");
                                                                        var resultant = [];
                                                                        if (locAddress.length > 0) {
                                                                            resultant = (locAddress[0].address_1).toLowerCase().includes(item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''))
                                                                                ? locAddress : [];
                                                                            if (resultant.length < 1) {
                                                                                let npiAdress = (locAddress[0].address_1).toLowerCase();
                                                                                let googleAddress = item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);

                                                                                let vicinity = item.vicinity.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);
                                                                                resultant = (npiAdress.includes(googleAddress) || npiAdress.includes(vicinity)) ? locAddress : [];
                                                                            }
                                                                        }
                                                                        if (resultant.length > 0) return true; else return false
                                                                    }) : [];

                                                                    if (filteredAddressItem.length > 0 || skip > 600)
                                                                        onClick({
                                                                            chain: item.pharmacyName.toLowerCase(),
                                                                            pharmacyName: item?.pharmacyName,
                                                                            latitude: item.lat,
                                                                            longitude: item.lng,
                                                                            zipcode: item.postalCode,
                                                                            address: item.formattedAddress,
                                                                            description: "",
                                                                            isDefault: userDetail.hasPharmacy ? 1 : 0,
                                                                            hasPharmacy: userDetail.hasPharmacy ? 1 : 0,
                                                                            state: item.political,
                                                                            npi: filteredAddressItem.length > 0 ? filteredAddressItem[0].number : null
                                                                        })
                                                                    else {
                                                                        skip += 200;
                                                                        getNPIRegistry(item.postalCode, skip, (npi) => {
                                                                            //find on basis of name
                                                                            const filteredNameItem = npi?.results.filter(i => {
                                                                                if ((i?.basic?.organization_name?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]/g, ''))?.includes(item?.pharmacyName.split(" ")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''))) return true;
                                                                                else if (i?.basic?.organizational_subpart == "YES") {
                                                                                    if ((i?.basic?.parent_organization_legal_business_name?.toLowerCase())?.includes(item?.pharmacyName.split(" ")[0].toLowerCase())) return true
                                                                                }
                                                                                else return false;
                                                                            });
                                                                            //find on basis of address
                                                                            const filteredAddressItem = filteredNameItem.length > 0 ? filteredNameItem.filter(it => {
                                                                                let locAddress = it.addresses.filter(ii => ii.address_purpose == "LOCATION");
                                                                                var resultant = [];
                                                                                if (locAddress.length > 0) {
                                                                                    resultant = (locAddress[0].address_1).toLowerCase().includes(item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''))
                                                                                        ? locAddress : [];
                                                                                    if (resultant.length < 1) {
                                                                                        let npiAdress = (locAddress[0].address_1).toLowerCase();
                                                                                        let googleAddress = item.formattedAddress.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);

                                                                                        let vicinity = item.vicinity.split(",")[0].toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 15);
                                                                                        resultant = (npiAdress.includes(googleAddress) || npiAdress.includes(vicinity)) ? locAddress : [];
                                                                                    }
                                                                                }
                                                                                if (resultant.length > 0) return true; else return false
                                                                            }) : [];

                                                                            if (filteredAddressItem.length > 0 || skip >= 600)
                                                                                onClick({
                                                                                    chain: item.pharmacyName.toLowerCase(),
                                                                                    pharmacyName: item?.pharmacyName,
                                                                                    latitude: item.lat,
                                                                                    longitude: item.lng,
                                                                                    zipcode: item.postalCode,
                                                                                    address: item.formattedAddress,
                                                                                    description: "",
                                                                                    isDefault: userDetail.hasPharmacy ? 1 : 0,
                                                                                    hasPharmacy: userDetail.hasPharmacy ? 1 : 0,
                                                                                    state: item.political,
                                                                                    npi: filteredAddressItem.length > 0 ? filteredAddressItem[0].number : tempNpi
                                                                                })
                                                                            // else {
                                                                            //     skip += 200;
                                                                            // }
                                                                        })
                                                                    }
                                                                },
                                                                () => {
                                                                    dispatch(setLoadingPharmacy(false));
                                                                    errorToast("Pharmacy information not available. Please verify your preferred pharmacy.")
                                                                })
                                                            }
                                                        },
                                                        () => {
                                                             dispatch(setLoadingPharmacy(false));
                                                            errorToast("Pharmacy information not available. Please verify your preferred pharmacy.")
                                                        })
                                                    }
                                                },
                                                () => {
                                                     dispatch(setLoadingPharmacy(false));
                                                    errorToast("Pharmacy information not available. Please verify your preferred pharmacy.")
                                                })
                                            }
                                        }}
                                    >
                                        {item.isDefault ? <>Selected <img src={blueCheck} style={{ marginLeft: "6px" }} /></> : "Select"}
                                    </button>
                                </div>
                                <div className="col-1 autoMargin" style={{ display: "flex", justifyContent: "center" }}>
                                    <div className="text-end">
                                        <img
                                            src={trash}
                                            className=" pointer"
                                            onClick={() => {
                                                if (item.userPharmacySeqNo) {
                                                    onDelete(item.userPharmacySeqNo);
                                                    setMarkers(markers => markers.filter((mark, i) => mark.lat != item.latitude && mark.lng != item.longitude))
                                                }
                                                else {
                                                    setPharmacyList(arr.filter((it, ind) => index != ind));
                                                    setMarkers(markers => markers.filter((mark, i) => mark.lat != item.lat && mark.lng != item.lng))
                                                }
                                            }
                                            }
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </div>
            {!isDesktop && <DotRow />}

        </div>
    )

}

const DotRow = () => {
    return (
        <div style={{ paddingTop: 8, paddingBottom: 8, zIndex: 120, height: 60, background: "white", bottom: 0, width: "100%", position: "fixed" }}>
            <div className="row" style={{ marginTop: 8 }}>
                <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
                    <div className="dot-main">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        {/* <span className="dot"></span> */}
                        <span className="dot dotActive"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}




