import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMedication, setMedication } from "../../reducers/medication";
import { fetchDrugsPrices } from "../../reducers/drugPrices";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";
import editPencil from "../../assets/images/RxLink assets/new assets/edit pen.svg"
import { fetchMedicationList } from "../../reducers/javaMedications";
import { deleteMedication } from "../../reducers/removeMedication";
import { addOrUpdateMedication } from "../../reducers/saveOrUpdateMedication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import editIcon from "../../assets/images/RxLink assets/new assets/edit pen.svg";
import PopupCouponCard from "../coupan/PopupCouponCard";
import { membersAssign, createRxSenseCard, setRxSenseCardInfo, createConnectiveRxCards, setConnectiveCards } from "../../reducers/assignMembers";
import { setSavedNpi, setTriggerModelFromCoupon, setPharmaInfo } from "../../reducers/medication";
import bestOffer from "../../assets/images/best-offer.png";
import cheaper from "../../assets/images/cheaper.png";
import arrowUp from '../../assets/images/RxLink assets/new assets/arrow/up.svg'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {
  AnimatePresence,
  motion,
  useAnimation,
  useAnimationControls,
} from "framer-motion";
import { wrapperVariants } from "../animationsVariants";
import { MotionIcon } from "../motion/MotionButton";
import { store } from "../../store";
import { setShowPahrmacyMap } from "../../reducers/medication";

import { setCouponData, setShowCoupon } from "../../reducers/medication";
import { searchPharmacyLogo } from "../PharmacyLogo";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
import { successToast, errorToast } from "../toast/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import validator from "validator";
import { setSMSOrEmailResponse, sndSMSOrEmail } from "../../reducers/sendSMSOrEmail";
import { SendSMSOrEmailModalWeb } from "../coupan/Modals/SendSMSorEmailModal";
import { useGetUser } from "../../utilities/useGetUser";
import { saveCareCardInfo, setSaveCareCardInfo } from "../../reducers/careCardInfo";
import { StringToHTML } from "../../utilities/StringToHtml";
const InitObject = {
  variant_id: 0,
  quantity: 0,
  package_size: 0,
  drugPrices: [],
};


export const getCrxBestPrice = (sub_element) => {
  let priceToAdd = 0;
  let priceToMinus = 0;
  sub_element?.drugs?.map((dr) => {
    if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
      priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
      priceToMinus += dr?.bestPrice;
    }
  })
  let newSum = sub_element?.bestPrice - priceToMinus + priceToAdd;
  return newSum?.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
}

export const getCard = (med, rxSenseCard, hippoCardInfo, connectiveCards, careCardInfo = {}, rxLessCard = {}) => {
  let connectCard = connectiveCards?.filter((crd) => crd?.ndc == med?.selectedDrug?.ndc);
  if (med?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
      return {
          pcn: connectCard?.[0]?.pcn,
          bin: connectCard?.[0]?.bin,
          memberId: connectCard?.[0]?.memberId,
          groupId: connectCard?.[0]?.groupId?.split(" ")?.[0],
          partner: med?.selectedDrug?.connectiveRxOffer?.partner === "CRx" ? "CRx" : "Doceree"
        }
  }
  else {
      return {
          pcn: med?.rxSense ? rxSenseCard?.pcn : med?.hasInsurance ? med?.InsuranceInfo.pcn : med?.isCareCard ? med?.careCardInfo?.pcn : med?.isRxLess ? med?.careCardInfo?.pcn : hippoCardInfo.pcn,
          bin: med?.rxSense ? rxSenseCard?.bin : med?.hasInsurance ? med?.InsuranceInfo.bin : med?.isCareCard ? med?.careCardInfo?.bin : med?.isRxLess ? med?.careCardInfo?.bin : hippoCardInfo.bin,
          memberId: med?.rxSense ? rxSenseCard?.memberId : med?.hasInsurance ? med?.InsuranceInfo.ID : med?.isCareCard ? med?.careCardInfo?.memberId : med?.isRxLess ? med?.careCardInfo?.memberId : hippoCardInfo.member_id,
          groupId: med?.rxSense ? rxSenseCard?.groupId : med?.hasInsurance ? med?.InsuranceInfo.Group : med?.isCareCard ? med?.careCardInfo?.groupId :med?.isRxLess ? med?.careCardInfo?.groupId : hippoCardInfo.group_id,
          hasInsurance: med?.hasInsurance,
          memberInfoSeqNo: med?.rxSense ? rxSenseCard?.memberInfoSeqNo : hippoCardInfo?.memberInfoSeqNo,
          careCardInfoSeqNo: careCardInfo ? careCardInfo?.careCardInfoSeqNo : '',
          partner: med?.rxSense ? "RxSense" : med?.hasInsurance ? "" : med?.isCareCard ? "CareCard" : med?.isRxLess ? "RxLess" : "Hippo"
      }
  }
}

const RightReviewCoupon = forwardRef(({
  medParam,
  valParam,
  setSavingOptionsPram,
  savingOptionParam,
  refParam,
  medDrugData,
  ownPosition,
  // pharmacyInfo,
  pricesList,
  medicationInfo,
  bestPackage,
  insuranceDetail,
  pharmaAddress,
  patient
}, ref) => {
  const [medications, setMedications] = useState([]);
  const [tempDrugsPrices, setTempDrugsPrices] = useState([]);
  const [finalDrugsPrices, setFinalDrugsPrices] = useState([]);
  const [tempSelectedObj, setTempSelectedObj] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [label, setLabel] = useState("");
  const [form, setForm] = useState("");
  const [dosage, setDosage] = useState("");
  const [type, setType] = useState("");
  const [drugPrices, setDrugPrices] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewSavingOptions, setSavingOptions] = useState(false);
  const [tempAllMedDrugsPrices, setTempAllMedDrugsPrices] = useState();
  const [tempAllMedDrugsPricesSelect, setTempAllMedDrugsPricesSelect] =
    useState();
  const [selectMapValues, setSelectMapValues] = useState([]);
  const [savingPercentage, setSavingPercentage] = useState(0);
  const [objPopupCoupon, setObjPopupCoupon] = useState({});
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isSaveValueForCoupon, setSaveValueForCoupon] = useState({});
  const userDetail = useGetUser();
  //var patientSeqNo = JSON.parse(localStorage.getItem("patientSeqNo"))
  const [memberAssign, setMemberAssign] = useState({});
  const [rxSenseCard, setRxSenseCard] = useState({});

  const [isShowPencilDiv, setShowPencilDiv] = useState(false);
  const [pharmacyInfo, setPharmacyInfo] = useState({});
  const [cheaperOptionAvailable, setCheaperOptionAvailable] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isEmailOrSMSSuccess, setIsEmailOrSMSSuccess] = useState(false);
  const [termsSMSorEmail, setTermsSMSorEmail] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [isEmailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isWalletURL, setWalletURL] = useState("");
  const controlsAnimation = useAnimationControls();

  const [loadingConnectiveCard, setLoadingConnectiveCard] = useState(false);
  const reduxState = store.getState();
  const rxSenseCardInfo = reduxState.membersAssignAPI.rxSenseCardInfo;
  const connectiveCards = reduxState.membersAssignAPI.connectiveCards;
  const careCards = reduxState?.careCardDetails?.careCardSave;
  const tooltipOfferRef = useRef(null);

  const controls = useAnimation();
  var numberPattern = /\d+/g;

  const { windowWidth } = useWindowWidth();
  const pharmacyState = JSON.parse(sessionStorage.getItem('selectMapValues'));

  const variants = {
    open: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    closed: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };
  const init = isShowPencilDiv ? { y: 100 } : { y: -155 };
  useEffect(() => {
    // const membersAssignData = JSON.parse(localStorage.getItem("membersAssign"));
    // if (membersAssignData) {
    //   setMemberAssign(membersAssignData);

    //   return;
    // }
    let body = {
      appUserSeqNo: userDetail?.appUserSeqNo,
      patientSeqNo: patient?.patientSeqNo
    };
    //let temp = "{\"member_id\":\"RKM7JDPFWW\", \"group_id\":\"002\", \"pcn\":\"CHIPPO\", \"bin\":\"019876\", \"phone\":\"\", \"phone_active\":true, \"email\":\"\", \"email_active\":true, \"attributes\":{}, \"links\":[{\"rel\":\"member_card_image\", \"href\":\"https://card.hippo.tools/image.png?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_website\", \"href\":\"https://hellohippo.com/add-to-wallet?member_id=RKM7JDPFWW\"}, {\"rel\":\"member_card_apple\", \"href\":\"https://wallet.hippo.tools?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_google\", \"href\":\"https://gwallet.hippo.tools/pass/redirect?member_id=RKM7JDPFWW\"}]}"
    //setMemberAssign(JSON.parse(temp));
    //console.log(JSON.parse(temp));
    setLoading(true);
    dispatch(membersAssign(body))
      .unwrap()
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          setMemberAssign(JSON.parse(response?.data));
          localStorage.setItem("membersAssign", response?.data);
        }
      })
      .catch((e) => {
        // console.log(e);
        setLoading(false);
      });
  }, [patient]);

  useEffect(() => {
    //const rxSenseCardData = JSON.parse(localStorage.getItem("rxSenseCard"));

    let body = {
      appUserSeqNo: userDetail?.appUserSeqNo,
      patientSeqNo: patient?.patientSeqNo
    };
    setLoading(true);
    dispatch(createRxSenseCard(body))
      .unwrap()
      .then((response) => {
        // if (response.data) {
        setRxSenseCard(response.data);
        dispatch(setRxSenseCardInfo(response.data))
        // }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [patient]);

  const getConnectiveCardDrugs = (drugs) => {
    let filteredConnectiveDrugs = drugs.filter((dr) => dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1);
    return filteredConnectiveDrugs || []
  }

  useEffect(() => {
    let connectiveDrugs = getConnectiveCardDrugs(medDrugData);
    if (connectiveDrugs.length > 0) {
      console.table(connectiveDrugs);
      let body = [];
      connectiveDrugs.map((it) => {
        body.push({
          appUserSeqNo: userDetail?.appUserSeqNo,
          drugNdc: it?.selectedDrug?.connectiveRxOffer?.drugNdc,
          crxNetPriceSubSeqNo: it?.selectedDrug?.connectiveRxOffer?.crxNetPriceSubSeqNo,
          dob: patient?.dob,
          gender: patient?.gender,
          patientSeqNo: patient?.patientSeqNo,
          pharmacySeqNo: pharmacyState?.pharmacySeqNo || "1213",
          pharmacyState: pharmacyState?.state || ownPosition?.responseZIP?.region_code || "",
          zipcode: pharmacyState?.zipCode || ownPosition?.postalCode || "",
          campaignEndDate: it?.selectedDrug?.connectiveRxOffer?.campaignEndDate,
          npi: pharmacyState?.npi,
          partner: it?.selectedDrug?.connectiveRxOffer?.partner
        })
      })
      setLoadingConnectiveCard(true);
      dispatch(createConnectiveRxCards({
        appUserSeqNo: userDetail?.appUserSeqNo,
        connectiveRxRequest: body
      }))
        .unwrap()
        .then((response) => {
          if (response?.statusCode == 200) {
            let cards = response?.data?.length > 0 && response?.data?.map((connective) => {
              if (connective?.statusCode == 200 && connective?.partner === "CRx") {
                return {
                  bin: connective?.programs?.[0]?.bin,
                  pcn: connective?.programs?.[0]?.pcn,
                  groupId: connective?.programs?.[0]?.group,
                  memberId: connective?.programs?.[0]?.memberId,
                  ndc: connective.drugNdc,
                };
              }
              else if (connective?.statusCode == 200 && connective?.partner === "Doceree") {
                return {
                  bin: connective?.docereeResponse?.bin,
                  pcn: connective?.docereeResponse?.pcn,
                  groupId: connective?.docereeResponse?.groupId,
                  memberId: connective?.docereeResponse?.memberID,
                  ndc: connective.drugNdc,
                };
              }
            });
            dispatch(setConnectiveCards(cards))
            // localStorage.setItem("rxSenseCard", JSON.stringify(response?.data));
          }
          else {
            dispatch(setConnectiveCards([]))
          }
          setLoadingConnectiveCard(false);
        })
        .catch((e) => {
          setLoadingConnectiveCard(false);
        });

    }
  }, [patient, medDrugData]);

  useEffect(() => {
    
    if ((isEmail || isSMS) && medDrugData?.length > 0) {
      const getUniqueCareCards = (careCardArr, keys) => {
        return careCardArr.filter((item, index, self) =>
          index === self.findIndex((t) => (
            keys.every(key => t[key] === item[key])
          ))
        );
      };
      const uniqueCareCardArr = getUniqueCareCards(medDrugData?.filter((x) => { if (x?.isCareCard) { return x; } }), ['bin', 'pcn', 'groupId']);
      if (uniqueCareCardArr?.length > 0) {
        uniqueCareCardArr?.forEach((element, ind) => {
          if (element?.isCareCard) {
            let saveCareCardBody = {
              "appUserSeqNo": userDetail?.appUserSeqNo,
              "ndc": element.selectedDrug.ndc,
              "price": element?.bestPrice,
              "estRetailPrice": element?.estRetailPrice,
              "quantity": element.selectedDrug.default_quantity,
              "pharmacyName": valParam.pharmacyName,
              "pharmacyNpi": valParam?.npi,
              "zipcode": valParam?.zipCode ? valParam?.zipCode : ownPosition?.postalCode,
              "bin": element?.careCardInfo?.bin,
              "pcn": element?.careCardInfo?.pcn,
              "groupId": element?.careCardInfo?.groupId,
              "memberId": element?.careCardInfo?.memberId
            };
            if (saveCareCardBody?.appUserSeqNo) {
              dispatch(saveCareCardInfo(saveCareCardBody))
                .unwrap().then((response) => {
                  if (response?.data) {
                    dispatch(setSaveCareCardInfo(response?.data));
                  }
                })
            }
          }
        })
      }


    }
  }, [medDrugData])

  useEffect(() => {
    if (rxSenseCardInfo?.bin) {
      localStorage.setItem("rxSenseCard", JSON.stringify(rxSenseCardInfo));
    }
    // console.log({rxSenseCardInfo})
  }, [rxSenseCardInfo])
  useEffect(() => {
    if (bestPackage?.length > 0) {
      let selectedPharmacy = bestPackage?.filter((x, i) => x?.chain === valParam?.chain);
      setPharmacyInfo(selectedPharmacy[0]);
      dispatch(setPharmaInfo(selectedPharmacy[0]));
    }
    else if (bestPackage?.length === 0) {
      setPharmacyInfo({});
      dispatch(setPharmaInfo({}))

    }

  }, [bestPackage])

  useEffect(() => {
    // console.log({valParam});
    let priceToAdd = 0;
    let priceToMinus = 0;
    pharmacyInfo?.drugs?.map((dr) => {
      if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
        priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
        priceToMinus += dr.bestPrice;
      }
    })
    let newSum = pharmacyInfo?.bestPrice - priceToMinus + priceToAdd;
    let costPercentage = 0;
    costPercentage = (newSum / pharmacyInfo?.estRetailPrice) * 100;
    let percentage = 100 - costPercentage;
    setSavingPercentage(percentage);
  }, [pharmacyInfo]);

  useImperativeHandle(ref, () => ({
    couponModelToCouponScreen() {
      if (isOpenCouponModal) {
        setIsOpenCouponModal(false);
        dispatch(setSavedNpi(valParam.npi))
        savingMap(pharmacyInfo, medParam, savingPercentage)
      }
    },
    couponScreenToCouponModel() {
      dispatch(setTriggerModelFromCoupon(false))
      savingMapTab(pharmacyInfo, medParam, savingPercentage)
    },
  }));

  const savingMap = (sub_element, pharmacyDrugsInfo) => {
    let obj = {
      drug: sub_element,
      pharmacyDrugsInfo: pharmacyDrugsInfo,
      savingPercentage: savingPercentage,
      memberAssign: memberAssign,
      isPharmacyInsShow: false,
      medDrugData: medDrugData,
      cheaperOptionAvailable: cheaperOptionAvailable
    };
    setSavingOptions(false);
    setSavingOptionsPram(false);
    dispatch(setCouponData(obj));
    dispatch(setShowCoupon(true));
    // navigate("/coupon", { state: obj });
  };

  const savingMapTab = (sub_element, pharmacyDrugsInfo) => {
    let obj = {
      drug: sub_element,
      pharmacyDrugsInfo: pharmacyDrugsInfo,
      savingPercentage: savingPercentage,
      memberAssign: memberAssign,
      isPharmacyInsShow: false,
      medDrugData: medDrugData,
    };
    setSaveValueForCoupon(obj);
    setIsOpenCouponModal(true);
    dispatch(setCouponData(obj));
    setObjPopupCoupon(obj);
    setSavingOptions(false);
    setSavingOptionsPram(false);
  };

  const hideCoupon = () => {
    setIsOpenCouponModal(false);
  };

  const navigationToCoupon = () => {
    setIsOpenCouponModal(false);
    navigate("/coupon", { state: isSaveValueForCoupon });
  };

  const handlePencilAction = () => {
    setIsOpenCouponModal(false);
    setSavingOptions(true);
    setSavingOptionsPram(true);
    window.scroll(0, 0);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Phone number is required").min(10, "Phone number must be 10 digits")
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema,

    onSubmit: (data) => {
      console.log("***********")
      sendSMS(data);
    },
  });

  const openSMSpopup = (e) => {
    formik.resetForm();
    setIsSMS(e);
    // formik.values.username = "";

  };

  const closeSMSOrEmailPopup = (e) => {
    setTermsError(false)
    setTermsSMSorEmail(false)
    setIsEmailOrSMSSuccess(e);
    formik.setTouched({}, false);
    setIsSMS(false);
    setIsEmail(false);
  }

  const openEmailPopup = (e) => {
    setIsEmail(e);
    setEmailError("");
  };

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailValue(email);
    setEmail(e.target.value);
    if (validator.isEmail(email)) {
      setEmailError("");
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      setEmailError("Enter valid email");
    }
  };

  function processInput(input) {
    if (typeof input === 'string' && input.startsWith('[') && input.endsWith(']')) {
      // Remove the brackets
      let trimmedInput = input.slice(1, -1);

      // Split the string by comma and trim each element
      let arrayOfStrings = trimmedInput.split(',').map(item => item.trim());

      return arrayOfStrings;
    } else {
      // Assume the input is already in the correct format
      return input;
    }
  }

  const sendSMS = (data) => {
    //  debugger;
    const { lat, lng, postalCode } = ownPosition;
    // if (termsSMSorEmail) {
    //   setTermsError(false)
    // } else {
    //   setTermsError(true)
    //   return;
    // }
    if(loading) return;
    if (isSMS) {
      if (data) {
        setLoading(true);
        let body = {
          sendSMS: true,
          mobileNo: data?.username.match(numberPattern).join(""),
          appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
          patientSeqNo: patient.patientSeqNo,
          memberInfo: memberAssign ? memberAssign : null,
          pharmacyName: pharmacyInfo?.pharmacyName,
          longitude: pharmacyState?.lng || ownPosition?.lng,
          latitude: pharmacyState?.lat || ownPosition?.lat,
          state: pharmacyState?.state,
          walletHash: patient.walletHash,
          pid: patient?.pid,
          drugs: medDrugData?.map((element, index) => {

            let med = medDrugData[index];
            if (med?.chain || med?.bestPrice > 0 || med?.hasInsurance) {
              let names = processInput(element?.selectedDrug?.names);

              const getName = () => {
                if (names.length > 1) {
                  return (
                    names[1] +
                    ` (${element?.selectedDrug?.name})`
                  );
                }
                else if (names.length == 1) {
                  return element?.selectedDrug?.name
                }
              }
              return {
                name: getName(),
                dosage: element?.selectedDrug?.dosage,
                form: element?.selectedDrug?.form,
                default_quantity: element?.selectedDrug?.default_quantity,
                price: med?.selectedDrug?.connectiveRxOffer?.hasCard == 1 ? med?.selectedDrug?.connectiveRxOffer?.price : med?.bestPrice,
                cardInfo: getCard(med, rxSenseCardInfo, memberAssign, connectiveCards, careCards)
              };
            }
          }).filter(Boolean),
        };
        dispatch(sndSMSOrEmail(body))
          .unwrap()
          .then((response) => {
            if (response?.status === "OK") {
              setIsSMS(false);
              //navigateToCouponSent();
              successToast(response?.message);
              dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
              localStorage.setItem(
                "memberInfo",
                JSON.stringify(response?.data?.memberInfo)
              );
              setWalletURL(response?.data?.walletUrl);
            } 
            else if( response?.status === "BANDWIDTH_LIMIT_EXCEEDED"){
              errorToast(<StringToHTML htmlString={"The coupon was already sent to this number. Please wait 10 minutes and if the coupon is still not received, try to use the email or mobile wallet option or email us at: <a href='mailto:support@rxlink.com'>support@rxlink.com</a>"} />);
            }
            else {
              errorToast(response?.message);
            }

          })
          .catch((e) => { })
          .finally((e) => {
            closeSMSOrEmailPopup(false);
            setLoading(false);
          });
      }
    }
  };

  const sendEmail = (flag) => {

    if (isEmail) {
      if (!isEmailValue) {
        setEmailError("Email is required");
        return;
      }

      if (!isValidEmail) {
        setEmailError("Enter valid email");
        return;
      }
      // if (termsSMSorEmail) {
      //   setTermsError(false)
      // } else {
      //   setTermsError(true)
      //   return;
      // }

      if(loading) return;
      if (flag) {
        setLoading(true);
        let body = {
          sendSMS: false,
          appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
          patientSeqNo: patient?.patientSeqNo,
          email: email,
          pharmacyName: pharmacyInfo?.pharmacyName,
          price: "$" + getCrxBestPrice(pharmacyInfo),
          state: pharmacyState?.state,
          walletHash: patient.walletHash,
          pid: patient?.pid,
          drugs: medDrugData?.map((element, index) => {
            let med = medDrugData[index];
            if (med?.chain || med?.bestPrice > 0 || med?.hasInsurance) {
              let names = processInput(element?.selectedDrug?.names);

              const getName = () => {
                if (names.length > 1) {
                  return (
                    names[1] +
                    ` (${element?.selectedDrug?.name})`
                  );
                }
                else if (names.length == 1) {
                  return element?.selectedDrug?.name
                }
              }
              return {
                name: getName(),
                dosage: element?.selectedDrug?.dosage,
                form: element?.selectedDrug?.form,
                default_quantity: element?.selectedDrug?.default_quantity,
                price: med?.selectedDrug?.connectiveRxOffer?.hasCard == 1 ? med?.selectedDrug?.connectiveRxOffer?.price : med?.bestPrice,
                cardInfo: getCard(med, rxSenseCardInfo, memberAssign, connectiveCards, careCards)
                // cardInfo: {
                //   pcn: med.rxSense ? rxSenseCard?.pcn : med.hasInsurance ? med.InsuranceInfo.pcn : memberAssign.pcn,
                //   bin: med.rxSense ? rxSenseCard?.bin : med.hasInsurance ? med.InsuranceInfo.bin : memberAssign.bin,
                //   memberId: med.rxSense ? rxSenseCard?.memberId : med.hasInsurance ? med.InsuranceInfo.ID : memberAssign.member_id,
                //   groupId: med.rxSense ? rxSenseCard?.groupId : med.hasInsurance ? med.InsuranceInfo.Group : memberAssign.group_id,
                //   hasInsurance: med.hasInsurance,
                // }
              };
            }
          }).filter(Boolean),
          // memberInfo: props?.objParam?.memberAssign
          //   ? props?.objParam?.memberAssign
          //   : null,
          longitude: pharmacyState?.lng || ownPosition?.lng,
          latitude: pharmacyState?.lat || ownPosition?.lat,
        };
        dispatch(sndSMSOrEmail(body))
          .unwrap()
          .then((response) => {
            if (response?.status === "OK") {
              setIsEmail(false);
              setTermsSMSorEmail(false);
              setEmailValue("");
              successToast(response?.message);
              dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
              localStorage.setItem(
                "memberInfo",
                JSON.stringify(response?.data?.memberInfo)
              );
            } else {
              setEmailValue("");
              errorToast(response?.message);
            }
          })
          .catch((e) => {

          }).finally((e) => {
            closeSMSOrEmailPopup(false);
            setLoading(false);
          });
      }
    }

  };

  const termsHandler = (e) => {
    setTermsSMSorEmail(e.target.checked)
    if (e.target.checked) {
      setTermsError(false)
    }
  }

  console.log({ pharmacyInfo, pharmaAddress })


  return (
    <>
      {pharmacyInfo?.bestPrice > 0 && (
        <>
          <section className="couponTabFix">

            <AnimatePresence>
              <motion.div
                className="col-sm-12 col-md-12 col-lg-12 couponMobFixed"
              // controls={controlsAnimation}
              // layout
              // key={'1'}
              // animate={'1'}
              // variants={variants}
              // initial={init}
              // style={{position: "relative"}}
              // exit={{y:100, transition:{ type: "tween", duration: 0.3}}}
              >
                <motion.div
                  className="medications_inner medications_inner_coupon dlr-Sup mt-0 mb-0 pos_rel"
                // initial={{ y: 10 }} animate={{ y: 0 }} transition={{ duration: 0.23, opacity: 0, type: "tween" }} exit={{y :150, transition:{ duration: 1, type: "tween"}}}
                >


                  <motion.div className="row">
                    <div className="col-7 col-sm-7 col-md-8 col-lg-12">
                      <motion.label className="">TOTAL OUT OF POCKET</motion.label>

                      <motion.div className="med-Flex">
                        <h1 className="webView div-hidden">
                          <sup>
                            <b>$&nbsp;</b>
                          </sup>
                          {/* {pharmacyInfo?.bestPrice?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{" "} */}
                          {getCrxBestPrice(pharmacyInfo)}
                        </h1>
                        <h2 className="tabView">
                          <sup>
                            <b>$&nbsp;</b>
                          </sup>
                          {getCrxBestPrice(pharmacyInfo)}
                        </h2>
                        <h3 className="mobileView" style={{ display: "flex" }}>
                          <sup style={{ paddingTop: 10, fontSize: 11 }}>
                            <b>$&nbsp;</b>
                          </sup>
                          {getCrxBestPrice(pharmacyInfo)}
                        </h3>
                        {savingPercentage > 0 && (
                          <p className="font13 flex-end">
                            {Math.round(savingPercentage)}%&nbsp;off{" "}
                          </p>
                        )}
                      </motion.div>

                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 webView div-hidden">
                      <motion.label className="hrDiv">PREFERRED PHARMACY</motion.label>

                      <motion.div className="review-coupon ">
                        <div className="row ">
                          <div className="col-sm-3 col-md-3 col-lg-3 autoMargin">
                            <div className="pharmacyImg_div">
                              <img
                                src={searchPharmacyLogo(pharmacyInfo?.pharmacyName, pharmacyInfo?.logo?.small)}
                                className="img-fluid"
                              // style={{maxWidth: 60}}
                              />
                            </div>
                          </div>
                          <div className="col-sm-7 col-md-7 col-lg-7 p-0 autoMargin">
                            <div className="" style={{ textAlign: "left" }}>
                              <p className="shopName">{pharmacyInfo?.pharmacyName} <br /> <span style={{ fontWeight: "normal" }}>{pharmaAddress?.address?.split(",")[0]}</span></p>
                            </div>
                          </div>


                          <div className="col-sm-2 col-md-2 col-lg-2 edit-Icon autoMargin text-end">
                            <MotionIcon>
                              <img className="cursor-pointer" src={editPencil} onClick={() => dispatch(setShowPahrmacyMap(true))} />
                            </MotionIcon>
                          </div>

                        </div>
                      </motion.div>
                      <p>Choose a method to send discounts to patient</p>

                    </div>
                    {/* pharmacyInfo?.cost */}

                    <motion.div
                      className="col-5 col-sm-5 col-md-4 col-lg-12 autoMargin"
                      transition={{ type: "tween" }}
                      // whileHover={{
                      //   scale: 1.04,
                      //   transition: { duration: 0.3 },
                      //   opacity: 1,
                      // }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <button
                        className="btn_success webView div-hidden review-coupon-button mt-2"
                        onClick={() => openSMSpopup(true)}
                      >
                        SMS
                      </button>
                      <button
                        className="btn_success webView div-hidden review-coupon-button mt-2"
                        onClick={() => openEmailPopup(true)}
                      >
                        Email
                      </button>
                      {/* Mobile btn */}
                      <button
                        className="btn_success mobileView seeCoupon"
                        onClick={() =>
                          savingMapTab(pharmacyInfo, bestPackage, savingPercentage)
                        }
                        style={{ padding: "12px 4px" }}
                      >
                        Send to patient
                      </button>

                      {/* tab btn */}
                      <button
                        className="btn_success tabView seeCoupon"
                        onClick={() =>
                          savingMapTab(pharmacyInfo, bestPackage, savingPercentage)
                        }
                      >
                        Send to patient
                      </button>
                    </motion.div>

                  </motion.div>


                </motion.div>
              </motion.div>
            </AnimatePresence>

          </section>
          <div className="col-sm-12 col-md-12 col-lg-3 couponMobFixed">
            <PopupCouponCard
              show={isOpenCouponModal}
              objParam={objPopupCoupon}
              close={() => hideCoupon()}
              navToCoupon={() => navigationToCoupon()}
              pencilAction={() => handlePencilAction()}
              drugPrices={medDrugData}
              ownPosition={ownPosition}
              cheaperOptionAvailable={cheaperOptionAvailable}
              windowWidth={windowWidth}
              insuranceDetail={insuranceDetail}
              pharmaAddress={pharmaAddress}
              patient={patient}
            />
          </div>

          <div className="col-sm-12 col-md-12 col-lg-3 couponMobFixed">
            <SendSMSOrEmailModalWeb
              isOpen={isSMS || isEmail || isEmailOrSMSSuccess}
              formik={formik}
              handleClose={() => closeSMSOrEmailPopup(false)}
              isSMS={isSMS}
              sendEmail={() => sendEmail(true)}
              sendSMS={formik.handleSubmit}
              validateEmail={validateEmail}
              emailError={emailError}
              hasSuccess={isEmailOrSMSSuccess}
              termsHandler={termsHandler}
              termsError={termsError}
            />
          </div>
        </>
      )}
    </>
  );
});

export default RightReviewCoupon;
